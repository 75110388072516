// returns a hash of errors

export default function validateAnswer(question, answer) {
  const { answer_format, id, units_name } = question

  const errors = {}

  console.log('question')
  console.log(question)

  console.log('answer')
  console.log(answer)

  const validNumberFormats = [ /^\d+$/, /^\d+\.\d+$/ ]

  if (answer_format === 'numeric') {
    const noCommaText = answer.text.replace(/\,/g, '')

    for ( let i in validNumberFormats ) {
      if (validNumberFormats[i].test(noCommaText)) {
        return errors
      }
    }
    errors['text'] = "Only numbers, commas, and decimals allowed."
  }

  const multiAnswers = [ "locations-with-single-input", "locations-with-utilities-input", "vehicles-with-single-input" ]

  if (multiAnswers.indexOf(answer_format) > -1) {
    for ( let key in answer ) {
      const noCommaText = answer[key]?.replace(/\,/g, '')
      const validMatches = validNumberFormats.map((format) => (format.test(noCommaText)))

      if (!validMatches.some(Boolean))
        errors[key] = "Invalid format"
    }
  }

  return errors
}
