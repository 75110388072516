import React from 'react'
import classnames from 'classnames'
import Tooltip from '../Tooltip'

import {metricChangeIsGood, displayArrow} from '../../lib/yoyUtilityFunctions'

const HomeStatBlock = ({title, amount, label, delta, status, slug, year}) => {
  const hideStat = status === 'action_required' && ((slug !== "waste-diversion") && (slug !== "total-emissions"))

  function smallOrLargeNumber(num) {
    if (typeof num === 'number') {
      if (num === 0) {
        // return an integer and never 0.0
        return 0
      } else {
        if (Math.abs(num) >= 1.0) {
          // it's a big number, neg or pos
          // let's display it to fixed 1 decimal point
          // unless it has no decimal place
          if (Math.abs(num) % 1 === 0) {
            // no decimal part
            return num.toFixed(0)
          } else {
            // just display the tenths place
            return num.toFixed(1)
          }
        } else {
          return num.toPrecision(2)
        }
      }
    } else {
      return '...'
    }
  }
  
  const normalizeStat = (amount) => {
    // if it's a string or something, just display it
    if (typeof amount !== 'number') {
      return amount || "-"
    }
    // if it's a number, and it's 0 or 0.0, display 0
    if (amount === 0) {
      return '-'
    }
    if (Number.isInteger(amount)) {
      // if it's an int, we can just add commas
      return amount.toLocaleString()
    } else {
      // if it's a float, we can put it in our decimal decorator
      return smallOrLargeNumber(amount).toLocaleString()
    }
  }

  const textToShowIfActionRequired = (slug) => {
    if(slug === "weekly-electricity") {
      return "Log into your electricity during your next carbon footprint"
    } else if(slug === "weekly-natural-gas") {
      return "Log into your natural gas during your next carbon footprint"
    } else {
      return "Complete your carbon footprint to see this metric"
    }
  }

  const showYoY = (year) => (year > 2023)

  return (
    <div className="statContainer">
      <div className={classnames('stat', {hideStat})}>
        <header>
          <h5>{title} {(slug === 'waste-diversion') && <Tooltip>Percentage of total waste diverted from the landfill to recycling, compost, animal feed, or anaerobic digestion.</Tooltip>} </h5>
        </header>
        <div className="statBody">
          <figure className="mainStat">
            <h3>{normalizeStat(amount)}{ label === "Rate" && amount && "%" }</h3>
            <label className='micro' >{label}</label>
          </figure>
          <figure className="pillText">
            <div className={classnames("micro", {pillStatGood: metricChangeIsGood(delta, slug, showYoY(year))})}>
              {displayArrow(showYoY(year) ? delta : null, slug)}
              <span>{delta && showYoY(year) ? `${smallOrLargeNumber(delta)}%` : `-- %`}</span>
              </div>
            <label className='micro' >vs. last year</label>
          </figure>
        </div>
      </div>
      {hideStat && <div className="hiddenStatText micro">{textToShowIfActionRequired(slug)}</div>}
    </div>
  )
}

export default HomeStatBlock