import React, { useState, useEffect } from 'react'

import LabeledField from '../Forms/LabeledField'
import Toggler from '../Forms/Toggler'
import NavButtons from './NavButtons'
import axios from 'axios'

import classnames from 'classnames'

import validateAnswer from '../../utils/validateAnswer'

export default function VehicleSingleInputQuestion(props) {

  const [ vehicles, setVehicles ] = useState([])

  const defaultAnswer = props.question.answer.vehicle_data.reduce(
    (acc, cur) => {
      acc[cur.vehicle_id] = cur.amount
      return acc
    }, {}
  )

  const [ answer, setAnswer ] = useState(defaultAnswer)
  const [ errors, setErrors ] = useState({})

  useEffect(() => {
    if(!vehicles.length) {
      axios.get('/api/vehicles').then(
        res => {
          setVehicles(res.data.vehicles)
        }
      ).catch(
        err => console.log(err)
      )
    }
  }, [])

  const updateAnswer = (e) => {
    setErrors({})
    const newAnswer = { ...answer }
    newAnswer[e.target.name] = e.target.value
    setAnswer(newAnswer)
  }

  const submit = () => {
    let newErrors = validateAnswer(props.question, answer)

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      return axios.patch('/api/questions/' + props.question.id, {
        answer: {
          vehicle_data: Object.keys(answer).map( vehicle_id => (
            {
              vehicle_id,
              amount: answer[vehicle_id],
              unit_id: props.question.unit.id
            }
          ))
        }
      })
    }
  }

  const nextEnabled = () => {
    const vehiclesDone = {}
    vehicles.forEach((v) => {
      vehiclesDone[v.id] = false
      if (answer[v.id]) {
        vehiclesDone[v.id] = true
      }
    })

    // false unless all true
    return !Object.values(vehiclesDone).includes(false)
  }


  const inputCssClasses = classnames("singleInputLocation", {
    dollarAmt: props.question.units_name === '$',
    nonDollarAmt: props.question.units_name !== '$',
    inlineUnitToggleMode: props.question.unit?.units?.length > 0
  })

  return (
    <div className="questionInputs">
      <div className={`oneField ${(props.question.units_name === '$' ? "dollarAmt" : "nonDollarAmt")} addressField`}>
      {vehicles.length === 0 && (
        <div>Tell us about your vehicles in the Business Info section to start.</div>
      )}
      {vehicles.length > 0 && vehicles.map( loc => {
          return <div className={classnames("addressRow", inputCssClasses)} key={loc.street_address + loc.zip_code}>
            <div className="locationName">
              <b>{loc.name || loc.street_address}</b>
            </div>
            <div className="locationAnswer">
              <LabeledField
                name={loc.id}
                formErrors={errors}
                values={answer}
                placeholder="Total"
                onChange={updateAnswer}
                className="unitsField"
              />
              <aside className={inputCssClasses}>{props.question.units_name}</aside>
            </div>
          </div>
        })}
      </div>
      <NavButtons
        noTopMargin
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
      />
    </div>
  )
}
