import React from 'react'
import { displayArrow, metricChangeIsGood } from '../../lib/yoyUtilityFunctions'
import classnames from 'classnames'
import {AgGridReact} from 'ag-grid-react'

import ChevronDown from '../../assets/ChevronDownBlack.svg'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const EmissionSection = ({section, handleAccordionClick, currentSectionId, previousSection, year=null}) => {

  const catHeads = ['Type', 'CO2', 'CH4', 'N2O', 'Other GHGs', 'Total kgCO2e', 'Source']

  if (process.env.REACT_APP_DEBUG) {
    catHeads.unshift('ID')
  }

  const breakdownNumbersForSection = (kg) => {
    return parseFloat(smallOrLargeNumber(kg)).toLocaleString()
  }

  function smallOrLargeNumber(num) {
    if (typeof num === "number") {
      return Math.abs(num) >= 1.0 ? num.toFixed(2) : num.toPrecision(2)
    } else {
      return '-'
    }
  }

  const formatData = (cell) => {
    return  <div style={{flex: 1}} className="body4">{smallOrLargeNumber(cell.value)}</div>
  }

  const qnnColDefs = [
    {field: "label", headerName: "Type", flex: 3,},
    {field: "co2.amount_kgco2e", headerName: "CO2", flex: 1, cellRenderer: formatData},
    {field: "ch4.amount_kgco2e", headerName: "CH4", flex: 1, cellRenderer: formatData},
    {field: "no2.amount_kgco2e", headerName: "NO2", flex: 1, cellRenderer: formatData},
    {field: "other", headerName: "Other GHGs", flex: 1, cellRenderer: formatData},  
    {field: "total_kgco2e", headerName: "Total kgCO2e", flex: 1, cellRenderer: formatData},
    {field: "source", flex: 1, cellRenderer: (props) => <div className="body4">{props.value}</div>},
  ]

  const showYoY = (year) => (year > 2023)

  const calcEmissionChangeValue = (current, prev) => (prev ? ((current - prev)/prev) : null)

  const defaultColDef = {
    flex: 1,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    cellStyle: {"wordBreak": "normal"},
  };

  const autoSizeStrategy = {
    type: 'fitGridWidth',
  };

  return (
    <div className="emissionCategory" key={section.section_id}>
      <Accordion>
        <AccordionSummary
          expandIcon={< img src={ChevronDown} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="headerContainer title">
            <h5>{ section.section_name }</h5>
            <span>
              <h5>{ breakdownNumbersForSection(section.total_kgco2e) } kgCO2e</h5>
              <figure className="pillText">
                <div className={classnames("micro", {pillStatGood: metricChangeIsGood(calcEmissionChangeValue(section.total_kgco2e, previousSection?.total_kgco2e), 'total-emissions', showYoY(year))})}>
                  {displayArrow((showYoY(year)) ? calcEmissionChangeValue(section.total_kgco2e, previousSection?.total_kgco2e) : null, 'total_emissions')}
                  <span>{previousSection?.total_kgco2e && (showYoY(year)) ? `${smallOrLargeNumber(calcEmissionChangeValue(section.total_kgco2e, previousSection?.total_kgco2e)*100)}%` : `-- %`}</span>
                  </div>
                <label className='micro' >vs. last year</label>
              </figure>
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="ag-theme-quartz full-container-table">
            <AgGridReact 
                domLayout='autoHeight'
                suppressDragLeaveHidesColumns 
                rowData={section.emissions_calculations?.map(ec => ({
                  ...ec, 
                  co2: ec.emissions.find((e) => (e.substance_slug === "carbon-dioxide")),
                  ch4: ec.emissions.find((e) => (e.substance_slug === "methane")),
                  no2: ec.emissions.find((e) => (e.substance_slug === "nitrous-oxide")),
                  other: ec.emissions.find((e) => (e.substance_slug === "other-greenhouse-gases"))
                }))} 
                columnDefs={qnnColDefs} 
                autoSizeStrategy={autoSizeStrategy}
                defaultColDef={defaultColDef}
                enableCellTextSelection
                ensureDomOrder
            />
          </div>
        </AccordionDetails>
          
      </Accordion>
    </div>
    )
}

export default EmissionSection