import React from 'react'

export default function Toggler(props) {
  const labels = props.labels ? props.labels : ['cu yds', 'Short tons']
  return (
    <label className={`switch ${props.className}`}>
      <input
        type="checkbox"
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
        value={props.value}
      />
      <span className="slider"></span>
      <div className="labelText">
        <span className="body4">{labels[0]}</span>
        <span className="body4">{labels[1]}</span>
      </div>
    </label>
  )
}
