import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'
import LoopIcon from '@mui/icons-material/Loop'
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff'
  
  
  export function metricChangeIsGood(delta, slug, showYoY) {
    if(!delta || !showYoY) {
      return false
    }
    const goodToGoDownMetrics = [ 
      'weekly-electricity', 
      'weekly-natural-gas', 
      'total-emissions', 
      'waste-generation', 
      'business', 
      'vehicle-equivalent', 
      'wastewater' 
    ]
    const goodToGoUpMetrics = [ 'waste-diversion' ]
    if (goodToGoUpMetrics.indexOf(slug) > -1) {
      return delta > 0
    }
    if (goodToGoDownMetrics.indexOf(slug) > -1) {
      return delta < 0
    }
  }

  export function displayArrow(delta) {
    if(!delta) {
      return <MobiledataOffIcon />
    }
    if(delta === 0) {
      return <LoopIcon />
    }
    if(delta > 0) {
      return <ArrowUpwardOutlinedIcon />
    } else {
      return <ArrowDownwardOutlinedIcon/>
    }
  }
  