import React, { useState, useEffect, useRef, useCallback } from 'react'

import { AgGridReact } from 'ag-grid-react'
import Select from 'react-select';
import EditIcon from '@mui/icons-material/Edit'
import LabeledSelect from '../../components/Forms/LabeledSelect';
import axios from 'axios'
import Modal from '../../components/Containers/Modal';
import CheckBox from '../../components/Forms/CheckBox';

export default function AdminVendors() {
  const [ supplierCategories, setSupplierCategories ] = useState([])
  const [ industries, setIndustries ] = useState([])
  const [ sectorShortNames, setSectorShortNames ] = useState([])
  const [ isNewSupCatOpen, setIsNewSupCatOpen ] = useState(false)
  const [ isNewEpaIndustryOpen, setIsNewEpaIndustryOpen ] = useState(false)
  const defaultSupplierCategory = {
    name: '',
    epa_industry_id: null,
    exclusion_category: false,
    sector_short_name_id: null
  }
  const defaultEpaIndustry = {
    name: '',
    code: '',
    sector_short_name_id: 18
  }
  const defaultEditItem = {
    supplier_category_id: null,
    epa_industry_id: null,
    name: null,
    code: null,
    short_name: null,
    exclusion_category: null,
    ghgp_scope3_category: null,
    sector_short_name: null
  }
  const [ supplierCategory, setSupplierCategory ] = useState(defaultSupplierCategory)
  const [ epaIndustry, setEpaIndustry ] = useState(defaultEpaIndustry)
  const [ formErrors, setFormErrors ] = useState({})
  const [ isLoading, setIsLoading ] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const supplierCategoryEpaIndustryInputRef = useRef()
  const supplierCategorySectorShortNameInputRef = useRef()
  const epaIndustrySectorShortNameInputRef = useRef()
  const [ editItem, setEditItem ] = useState({})

  useEffect(() => {
    axios.get('/api/supplier_categories').then((res) => {
      setSupplierCategories(res.data)
    })
  }, [])

  useEffect(() => {
    axios.get('/api/epa_industries').then((res) => {
      setIndustries(res.data)
    })
  }, [])

  useEffect(() => {
    axios.get('/api/sector_short_names').then((res) => {
      setSectorShortNames(res.data)
    })
  }, [])

  const linkedIndustryIds = new Set(supplierCategories.map(category => category.epa_industry.id));

  const unlinkedEpaIndustries = industries.filter(industry => !linkedIndustryIds.has(industry.id));

  const selectOptions = unlinkedEpaIndustries.map( industry => ({
    value: industry.id,
    label: `(${industry.code}) ${industry.name}`
  })
)

  const createSupCat = () => {
    axios.post('/api/supplier_categories', {supplier_category: supplierCategory}).then((res) => {
      axios.get('/api/supplier_categories').then((res) => {
        setSupplierCategories(res.data)
        supplierCategoryEpaIndustryInputRef.current.clearValue()
        supplierCategorySectorShortNameInputRef.current.clearValue()
        setSupplierCategory(defaultSupplierCategory)
      })
    })
  }

  const createEpaIndustry = () => {
    axios.post('/api/epa_industries', {epa_industry: epaIndustry}).then((res) => {
      axios.get('/api/epa_industries').then((res) => {
      setIndustries(res.data)
      setEpaIndustry(defaultEpaIndustry)
      axios.get('/api/supplier_categories').then((res) => {
          setSupplierCategories(res.data)
          epaIndustrySectorShortNameInputRef.current.clearValue()
          setSupplierCategory(defaultSupplierCategory)
        })
      })
    })
  }

  const openEditModal = (data) => {
    setModalOpen(true)
    const {id, exclusion_category, code, name, epa_industry_id, short_name, ghgp_scope3_category, sector_short_name} = data;
    setEditItem({
      supplier_category_id: id,
      epa_industry_id,
      exclusion_category,
      code,
      name,
      short_name,
      ghgp_scope3_category,
      sector_short_name
    })
  }

  const supCatColDefs = [
    {field: "code", flex: 1},
    {field: "name", flex: 2},
    {field: "short_name",  headerName: 'Short Name', flex: 2},
    {field: "ghgp_scope3_category",  headerName: 'GHGP Cat', flex: 2, cellRenderer: (props) => (
      <div>{ categories.find(cat => String(cat.value) === props.data.ghgp_scope3_category)?.label }</div>
    )},
    {field: "exclusion_category", headerName: 'Excluded?', flex: 0.5},
    {field: "sector_short_name", headerName: 'Sector Short Name', flex: 2},
    {field: "id", headerName: "Edit", flex: 0.5, cellRenderer: (props) => (
      <div className="icon-container edit" onClick={() => openEditModal(props.data)}><EditIcon/></div>
    )},
  ]

  const autoSizeStrategy = {
    type: 'fitGridWidth',
  };

  const categories = [
  { label: "N/A", value: 0 },
  { label: "1: Purchased goods and services", value: 1 },
  { label: "2: Capital goods", value: 2 },
  { label: "3: Fuel- and energy-related activities", value: 3 },
  { label: "4: Upstream transportation and distribution", value: 4 },
  { label: "5: Waste generated in operations", value: 5 },
  { label: "6: Business travel", value: 6 },
  { label: "7: Employee commuting", value: 7 },
  { label: "8: Upstream leased assets", value: 8 }
];

  let itemToBeEdited = defaultEditItem
  let indexOfChangedItem = -1

  if(editItem.supplier_category_id) {
    itemToBeEdited = supplierCategories.find( (supCat, index) => {
      if(supCat.id === editItem.supplier_category_id) {
        indexOfChangedItem = index;
      }
      return supCat.id === editItem.supplier_category_id})

  }

  const findSectorShortName = (str) => {
    return sectorShortNames.find(ssn => ((ssn.id === str) || (ssn.name === str)))
  }

  const saveChanges = () => {
    const newSupplierCategories = supplierCategories.slice()


    const updateEpaIndustryName = (id, value) => axios.patch('/api/epa_industries/' + id, {name: value}).then(
      (res) => { console.log('success')
    }).catch(e => console.log(e, 'failed'))

    const updateSupplierCategoryInfo = (id, name, exclusionCategory, ghgpScope3Cat, sectorShortNameId) => axios.patch('/api/supplier_categories/' + id, {name, sector_short_name_id: findSectorShortName(sectorShortNameId).id, exclusion_category: exclusionCategory, ghgp_scope3_category: ghgpScope3Cat}).then(
      (res) => {
        console.log('success')
    }).catch(e => console.log(e, 'failed'))

    if(editItem.name && editItem.name !== itemToBeEdited.epa_industry.name) {
      updateEpaIndustryName(editItem.epa_industry_id, editItem.name)      
      newSupplierCategories[indexOfChangedItem].epa_industry.name = editItem.name
    }
    if((editItem.short_name !== itemToBeEdited.name) || (editItem.exclusion_category !== itemToBeEdited.exclusion_category) || (editItem.ghgp_scope3_category !== itemToBeEdited.ghgp_scope3_category) || ((editItem.sector_short_name) !== (itemToBeEdited.sector_short_name))) {
      updateSupplierCategoryInfo(editItem.supplier_category_id, editItem.short_name, editItem.exclusion_category, editItem.ghgp_scope3_category, editItem.sector_short_name)
      newSupplierCategories[indexOfChangedItem].name = editItem.short_name
      newSupplierCategories[indexOfChangedItem].exclusion_category = editItem.exclusion_category
      newSupplierCategories[indexOfChangedItem].ghgp_scope3_category = editItem.ghgp_scope3_category
      newSupplierCategories[indexOfChangedItem].sector_short_name = findSectorShortName(editItem.sector_short_name).name
    }
    setSupplierCategories(newSupplierCategories)
    setModalOpen(false)
  }

  const defaultColDef = {
    flex: 1,
    resizable: true,
    sortable: true,
    wrapText: true,
    cellStyle: {"wordBreak": "normal"},
  };

  const sectorShortNameOptions = sectorShortNames.map( ({id, name}) => ({label: name, value: id}))

  const CreateSupplierCategoryBody = (
    <div>
      <div className="micro">Name & Code</div>
      <Select
        isDisabled={isLoading}
        isClearable
        ref={supplierCategoryEpaIndustryInputRef}
        isLoading={isLoading}
        onChange={(val) => setSupplierCategory({...supplierCategory, epa_industry_id: val ? val.value : null})}
        options={selectOptions}
        value={selectOptions.find(option => option.value === supplierCategory.epa_industry_id)}
      />
      <br/>
      <div className="micro">Sector Short Name</div>
      <Select
        options={sectorShortNameOptions}
        value={ sectorShortNameOptions.find(ssn => supplierCategory.sector_short_name_id === ssn.value) }
        ref={supplierCategorySectorShortNameInputRef}
        onChange={(e) => setSupplierCategory({...supplierCategory, sector_short_name_id: e ? e.value : null})}
      />
      <br/>
      <div className="micro">Short Name</div>
      <input
        name={'name'}
        type={'text'}
        key={'name'}
        value={supplierCategory['name']}
        error={!!formErrors['name']}
        fullWidth
        onChange={(e) => setSupplierCategory({...supplierCategory, name: e.target.value})}
        helperText={formErrors['name']}
      />
      <br/>
      <div>
      <input 
        disabled={!supplierCategory.epa_industry_id || !supplierCategory.name} 
        className='positive' 
        type='button' 
        value='Create New EPA Category' 
        onClick={createSupCat}
      />
      </div>
      <br/>
    </div>
  )

  const CreateEpaIndustryBody = (
      <div>
      <div className="micro">Code</div>
        <input
          name={'code'}
          type={'text'}
          key={'code'}
          onChange={(e) => setEpaIndustry({...epaIndustry, code: e.target.value})}
          value={epaIndustry.code}
        />
        <br/>
        <div className="micro">Sector Short Name</div>
        <Select
          options={sectorShortNameOptions}
          value={ sectorShortNameOptions.find(ssn => epaIndustry.sector_short_name_id === ssn.value) }
          ref={epaIndustrySectorShortNameInputRef}
          onChange={(e) => setEpaIndustry({...epaIndustry, sector_short_name_id: e ? e.value : null})}
        />
        <br/>
        <div className="micro">Name</div>
        <input
          name={'name'}
          type={'text'}
          key={'name'}
          value={epaIndustry['name']}
          onChange={(e) => setEpaIndustry({...epaIndustry, name: e.target.value})}
        />
        <br/>
        <div>
        <input 
          disabled={!epaIndustry.code || !epaIndustry.name || industries.filter(ind => epaIndustry.code.toLocaleLowerCase() === ind.code.toLocaleLowerCase()).length} 
          className='positive' 
          type='button' 
          value='Create New CH Category' 
          onClick={createEpaIndustry}
        />
        </div>
        <br/>
      </div>
    )

    const editModalButtons = (
    <div>
      <input 
          type='button' 
          value='Cancel' 
          onClick={() => setModalOpen(false)}
      />
      <input 
          disabled={(editItem?.ghgp_scope3_category === itemToBeEdited?.ghgp_scope3_category) && (editItem.name && editItem.name === itemToBeEdited?.epa_industry?.name) && (editItem.short_name && editItem.short_name === itemToBeEdited.name) && (editItem.exclusion_category === itemToBeEdited.exclusion_category) && (findSectorShortName(editItem.sector_short_name).id === findSectorShortName(itemToBeEdited.sector_short_name).id)} 
          className='positive' 
          type='button' 
          value='Save changes' 
          onClick={saveChanges}
      />
    </div>)

    console.log(editItem)

    const editModalBody = (
      <div>
        <div>
          <div className="micro">Name</div>
          <input
            name={'name'}
            type={'text'}
            key={'name'}
            value={editItem['name']}
            error={!!formErrors['name']}
            fullWidth
            onChange={(e) => setEditItem({...editItem, name: e.target.value})}
            helperText={formErrors['name']}
          />
        </div>
        <div>
          <div className="micro">Short Name</div>
          <input
            name={'short_name'}
            type={'text'}
            key={'short_name'}
            value={editItem['short_name']}
            error={!!formErrors['short_name']}
            fullWidth
            onChange={(e) => setEditItem({...editItem, short_name: e.target.value})}
            helperText={formErrors['short_name']}
          />
        </div>
        <br/>
        <div>
          <LabeledSelect 
            name="ghgp_scope3_category"
            label={'GHG Scope 3 Cat'}
            options={categories.map( ({label, value}) => ({label, value}))}
            values={editItem}
            onChange={(e) => setEditItem({...editItem, ghgp_scope3_category: e.target.value})}
          />
        </div>
        <br/>
        <LabeledSelect 
          name="sector_short_name"
          label={'Sector Short Name'}
          options={sectorShortNames.map( ({id, name}) => ({label: name, value: id}))}
          values={{
            sector_short_name: findSectorShortName(editItem['sector_short_name'])?.id
          }}
          onChange={(e) => setEditItem({...editItem, sector_short_name: e.target.value})}
          />
        <br/>
        <div>
          <CheckBox
            label='Exclusion Category'
            name='exclusion_category'
            onChange={() => setEditItem({...editItem, exclusion_category: !editItem.exclusion_category})}
            values={editItem}
          />
        </div>
      </div>
    )

    const tableData = supplierCategories.map(
      supCat => ({ 
        id: supCat.id, 
        exclusion_category: supCat.exclusion_category, 
        code: supCat.epa_industry?.code, 
        name: supCat.epa_industry?.name, 
        epa_industry_id: supCat.epa_industry?.id, 
        short_name: supCat.name, 
        ghgp_scope3_category: `${supCat.ghgp_scope3_category}`, 
        sector_short_name: supCat.sector_short_name
      })
    )

    return(
      <div className="adminPage admin-table-wrapper">
        <h1>Vendor Emissions Categories</h1>
        <Modal
          title={"Confirm Update for " + editItem.code}
          className="updateVendorCategoryModal"
          open={modalOpen}
          close={() => setModalOpen(false)}
          bodyDom={editModalBody}
          actionsDom={editModalButtons}
      />
        <div style={{display: 'flex', gap: 16}}>
          <input 
            type='button' 
            value={isNewSupCatOpen ? "Hide EPA Category Creation" : "Create New EPA Category" } 
            onClick={() => {
              setIsNewEpaIndustryOpen(false)
              setIsNewSupCatOpen(!isNewSupCatOpen)
            }}
          />
          <input 
            type='button' 
            value={isNewEpaIndustryOpen ? "Hide CH Category Creation" : "Create New CH Category" } 
            onClick={() => {
              setIsNewSupCatOpen(false)
              setIsNewEpaIndustryOpen(!isNewEpaIndustryOpen)
            }}
          />        
        </div>

        {
          isNewSupCatOpen && (
            <div>
              {CreateSupplierCategoryBody}
            </div>
          )
        }

        {
          isNewEpaIndustryOpen && (
            <div>
              {CreateEpaIndustryBody}
            </div>
          )
        }
        {!!supplierCategories.length && (
          <div className="ag-theme-quartz full-container-table">
            <AgGridReact
              domLayout='autoHeight'
              suppressDragLeaveHidesColumns
              rowData={tableData}
              columnDefs={supCatColDefs}
              autoSizeStrategy={autoSizeStrategy}
              defaultColDef={defaultColDef}
              enableCellTextSelection
              ensureDomOrder
            />
          </div>
        )}
      </div>
  )
}
