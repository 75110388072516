import React, { useState, useEffect, useMemo } from 'react'
import { startCase } from 'lodash'

import { interpolateViridis } from 'd3-scale-chromatic'
import Bar from '../Charts/Bar'
import Donut from '../Charts/Donut'
import axios from 'axios'
import LabeledSelect from "../Forms/LabeledSelect"
import CircularProgress from '@mui/material/CircularProgress';

import DashboardIcon from '../../assets/Dashboard.svg'
import returnDeviceType from '../../lib/returnDeviceType'
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter'
import EmissionSection from './EmissionSection'
import HomeStatBlock from './HomeStatBlock'

export default function CarbonFootprint() {

  const [ deviceType , setDeviceType ] = useState()
  const [ bySection, setBySection ] = useState([])
  const [ bySectionPrevious, setBySectionPrevious ] = useState([])
  const [ scopeMap, setScopeMap ] = useState([])
  const [ procurement, setProcurement ] = useState([])
  const [ scope3Summary, setScope3Summary ] = useState([])
  const [ substanceMap, setSubstanceMap ] = useState({})
  const [ reportSummary, setReportSummary ] = useState({})
  const [ currentSectionId, setCurrentSectionId ] = useState(null)
  const [ questionnaires, setQuestionnaires ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const params = new URLSearchParams(window.location.search)
  const [reportId, setReportId] = useState(params.get('report_id'))
  const userId = params.get('user_id')


  const COLORS = {
    yellow: "#FDCA37",
    red: "#E45400",
    darkBlue: "#1D60C6",
    blue: "#43C1F0"
  }


  const orgSummaryColors = {
    "Distribution and Travel": COLORS['yellow'],
    "Procurement": COLORS['red'],
    "Waste Management": COLORS['darkBlue'],
    "Energy Usage": COLORS['blue']
  }

  const summaryGHGColors = {
    "CH4": COLORS['yellow'],
    "N2O": COLORS['red'],
    "CO2": COLORS['darkBlue'],
    "Other": COLORS['blue']
  }

  const scopeSummaryColors = {
    "Scope 1": COLORS['blue'],
    "Scope 2": COLORS['yellow'],
    "Scope 3": COLORS['red'],
  }

  useEffect(() => {
    axios.get('/api/questionnaires/' + (userId ? `?user_id=${userId}` : "")).then((res) => {
      const formattedData = res.data.filter(q => q.has_report)
      setQuestionnaires(formattedData)
      console.log(formattedData)
    }).catch(
      err => {
        console.log(err)
      }
    )
  }, [])


  const colors = []

  const [ currentBar, setCurrentBar ] = useState('general')

  const [ statBlocks, setStatBlocks] = useState([])

  const [ total, setTotal ] = useState(0.0)
  const [ year, setYear ] = useState('')

  useEffect(() => {
    let url = `/api/reports/${reportId || "current"}/summary`
    setDeviceType(returnDeviceType())
    setLoading(true)
    axios.get(url).then((res) => {
      setReportSummary(res.data)
      setStatBlocks(res.data.metrics)
      setTotal(res.data.total_kgco2e)
      setBySection(res.data.emissions_by_section)
      setBySectionPrevious(res.data.previous_emissions_by_section)
      setProcurement(res.data.bulk_procurement_emissions_calculations)
      setScope3Summary(res.data.scope_3_summary)
      setSubstanceMap(res.data.substance_map)
      setScopeMap(res.data.scope_map)
      setYear(res.data.year)
      setLoading(false)
    })
  }, [reportId])

  const handleTableResize = () => {
      setDeviceType(returnDeviceType()) 
  }

  useEffect(() => {
    window.addEventListener('resize', handleTableResize)
    handleTableResize()
    return (() => {
      window.removeEventListener('resize', handleTableResize)
    })
  }, [])

  const setCurrentBarChart = (slug) => {
    if (slug === currentBar)
      return
    setCurrentBar(slug)
  }

  const barSummary = []
  for (let i = 0; i < 18; i++) {
    barSummary.push(i + 1)
  }

  // const orgSummary = [
  //   {name: 'Travel', kgs: 124, percent: 10},
  //   {name: 'Utilities', kgs: 248.4, percent: 20},
  //   {name: 'Waste', kgs: 149.04, percent: 12},
  //   {name: 'Purchased Goods', kgs: 621, percent: 40}
  // ]

  const generateOrgSummary = () => {
    const orgs = []

    if (bySection) {
      for (let i = 0; i < bySection.length; i++) {
        orgs.push({
          name: bySection[i].section_name,
          kgs: bySection[i].total_kgco2e * 0.001,
          percent: (bySection[i].total_kgco2e / total) * 100.0
        })
      }
    }

    return orgs

  }

  const orgSummary = useMemo(
    () => generateOrgSummary(),
    [bySection]
  );

  for (let i = 0; i < orgSummary.length; i++) {
    colors.push(interpolateViridis(i / orgSummary.length))
  }

  const generateScopeSummary =  () => {
      return[
        {name: 'Scope 1', kgs: scopeMap['SCOPE_1'] * 0.001, percent: (scopeMap['SCOPE_1'] / total) * 100},
        {name: 'Scope 2', kgs: scopeMap['SCOPE_2'] * 0.001, percent: (scopeMap['SCOPE_2'] / total) * 100},
        {name: 'Scope 3', kgs: scopeMap['SCOPE_3'] * 0.001, percent: (scopeMap['SCOPE_3'] / total) * 100},
      ]
  }

  const scopeSummary = useMemo(
    () => generateScopeSummary(),
    [scopeMap]
  );

  const generateSubstanceSummary = () => {
    return [
      {name: 'CO2', kgs: substanceMap[1] * 0.001, percent: ((substanceMap[1] / total) * 100)},
      {name: 'CH4', kgs: substanceMap[2] * 0.001, percent: ((substanceMap[2] / total) * 100)},
      {name: 'N2O', kgs: substanceMap[3] * 0.001, percent: ((substanceMap[3] / total) * 100)},
      {name: 'Other', kgs: substanceMap[4] * 0.001, percent: ((substanceMap[4] / total) * 100)}
    ]
  }

  const substanceSummary = generateSubstanceSummary();

  function getGradientColors(numColors) {
  // Define the gradient stops
  const gradientStops = [
    { offset: 0, color: [29, 96, 198] }, // #1D60C6 (Deep Blue)
    { offset: 0.5, color: [228, 84, 0] }, // #E45400 (Deep Orange-Red)
    { offset: 1, color: [253, 202, 55] } // #FDCA37 (Yellow-Gold)
  ];

    // Helper to interpolate between two colors
    function interpolateColor(color1, color2, t) {
      return [
        Math.round(color1[0] + (color2[0] - color1[0]) * t),
        Math.round(color1[1] + (color2[1] - color1[1]) * t),
        Math.round(color1[2] + (color2[2] - color1[2]) * t)
      ];
    }

    // Generate the colors
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const t = i / (numColors - 1);

      // Find the two gradient stops surrounding t
      let startStop, endStop;
      for (let j = 0; j < gradientStops.length - 1; j++) {
        if (t >= gradientStops[j].offset && t <= gradientStops[j + 1].offset) {
          startStop = gradientStops[j];
          endStop = gradientStops[j + 1];
          break;
        }
      }

      // Interpolate between the two stops
      const relativeT =
        (t - startStop.offset) / (endStop.offset - startStop.offset);
      const interpolatedColor = interpolateColor(
        startStop.color,
        endStop.color,
        relativeT
      );

      // Convert the color to a hex string and add to the array
      colors.push(
        `#${interpolatedColor
          .map((channel) => channel.toString(16).padStart(2, "0"))
          .join("")}`
      );
    }

    return colors;
  }

  const substanceSummaryLabels = useMemo(
    () => substanceSummary.map((ss) => (ss.name)),
    [substanceMap]
  )

  const substanceSummaryData = useMemo(
    () => substanceSummary.map((ss) => (ss.kgs)),
    [substanceMap]
  )

  const summaryColors = orgSummary.map((d) => orgSummaryColors[d.name])

  const impactTableRows = orgSummary.map((d, idx) => {
    return (
      <tr key={d.name}>
        <td>
        <span className='labelContainer'>
          <span className="keySwatch" style={{backgroundColor: summaryColors[idx]}}></span>
          <span className='label'>{d.name}</span>
        </span>
        </td>
        <td>{smallOrLargeNumber(d.kgs)}</td>
        <td>{smallOrLargeNumber(d.percent)}%</td>
      </tr>
    )
  })

  const substanceSummaryColors = substanceSummary.map(d => summaryGHGColors[d.name])

  const substanceTableRows = substanceSummary.map((d, idx) => {
    return (
      <tr key={d.name}>
        <td>
        <span className='labelContainer'>
          <span className="keySwatch" style={{backgroundColor: substanceSummaryColors[idx]}}></span>
          <span className='label'>{d.name}</span>
        </span>
        </td>
        <td>{smallOrLargeNumber(d.kgs)}</td>
        <td>{smallOrLargeNumber(d.percent)}%</td>
      </tr>
    )
  })


  const scopeColors = scopeSummary.map(d => scopeSummaryColors[d.name])

  const scopeTableRows = scopeSummary.map((d, idx) => {
    return (
      <tr key={d.name}>
        <td>
        <span className='labelContainer'>
          <span className="keySwatch" style={{backgroundColor: scopeColors[idx]}}></span>
          <span className='label'>{d.name}</span>
        </span>
        </td>
        <td>{smallOrLargeNumber(d.kgs)}</td>
        <td>{smallOrLargeNumber(d.percent)}%</td>
      </tr>
    )
  })


  const kitchenBar = () => {
    const kitchenSection = bySection.find((sec) => (sec.section_name === "Kitchen"))
    const labels = []
    const values = []
    if (kitchenSection) {
      for (let i = 0; i < kitchenSection.emissions_calculations.length; i++) {
        labels.push(kitchenSection.emissions_calculations[i]['label'])
        values.push(kitchenSection.emissions_calculations[i]['total_kgco2e'])
      }
    }
    return [labels, values]
  }

  const kitchenSection = bySection.find((sec) => (sec.section_name === "Kitchen"))

  const kitchenBarData = useMemo(
    () => kitchenBar(),
    [bySection]
  );

  const kitchenBarDataColors = getGradientColors(kitchenSection?.emissions_calculations?.length)

  const kitchenBarDataTableRows = (numberOfTables , tableNumber) => 
  kitchenSection?.emissions_calculations.map((d, idx) => {
    return (
      idx >= (tableNumber * Math.ceil(kitchenSection?.emissions_calculations.length / numberOfTables)) && (idx < (tableNumber + 1) * Math.ceil(kitchenSection?.emissions_calculations.length / numberOfTables))  &&
      (
        <tr key={d.name}>
        <td>
        <span className='labelContainer'>
          <span className="keySwatch" style={{backgroundColor: kitchenBarDataColors[idx]}}></span>
          <span className='label'>{d.label}</span>
        </span>
        </td>
        <td>{smallOrLargeNumber(d.total_kgco2e * 0.001)}</td>
        <td>{smallOrLargeNumber((d.total_kgco2e / scopeMap['SCOPE_3']) * 100.0)}%</td>
      </tr>
    ))
  })

  const procurementBar = () => {
    const labels = []
    const values = []
    if (procurement && procurement.length) {
      for (let i = 0; i < procurement.length; i++) {
        labels.push(procurement[i]['label'])
        values.push(procurement[i]['total_kgco2e'])
      }
    }
    return [labels, values]
  }

  const procurementBarData = useMemo(
    () => procurementBar(),
    [procurement]
  );

  const procurementBarDataColors = []

  for (let i = 0; i < procurement.length; i++) {
    procurementBarDataColors.push(interpolateViridis(i / procurement.length))
  }

  const procurementBarDataTableRows = (numberOfTables , tableNumber) => 
  procurement.map((d, idx) => {
    return (
      idx >= (tableNumber * Math.ceil(procurement.length / numberOfTables)) && (idx < (tableNumber + 1) * Math.ceil(procurement.length / numberOfTables))  &&
      (
        <tr key={d.name}>
        <td>
          <span className='labelContainer'>
            <span className="keySwatch" style={{backgroundColor: procurementBarDataColors[idx]}}></span>
            <span className='label' >{d.label}</span>
          </span>
        </td>
        <td>{smallOrLargeNumber(d.total_kgco2e * 0.001)}</td>
        <td>{smallOrLargeNumber((d.total_kgco2e / scopeMap['SCOPE_3']) * 100.0)}%</td>
      </tr>
    ))
  })

  const scope3Colors = []
  const scope3SummaryLabels = useMemo(
    () => scope3Summary.map((sum) => (startCase(sum.question_category.name))),
    [scope3Summary]
  )
  const scope3SummaryData = useMemo(
    () => scope3Summary.map((sum) => (sum.total_kgco2e)),
    [scope3Summary]
  )
  for (let i = 0; i < scope3Summary.length; i++) {
    scope3Colors.push(interpolateViridis(i / scope3Summary.length))
  }
  const scope3TableRows = (numberOfTables , tableNumber) => 
  scope3Summary.map((d, idx) => {
    return (
      idx >= (tableNumber * Math.ceil(scope3Summary.length / numberOfTables)) && (idx < (tableNumber + 1) * Math.ceil(scope3Summary.length / numberOfTables))  &&
      (
        <tr key={d.name}>
          <td>
            <span className='labelContainer'>
              <span className="keySwatch" style={{backgroundColor: scope3Colors[idx]}}></span>
              <span className='label'>{d.question_category.name}</span>
            </span>
          </td>
          <td>{smallOrLargeNumber(d.total_kgco2e * 0.001)}</td>
          <td>{smallOrLargeNumber((d.total_kgco2e / scopeMap['SCOPE_3']) * 100.0)}%</td>
      </tr>
    ))
  })

  function smallOrLargeNumber(num) {
    if (typeof num === "number") {
      return Math.abs(num) >= 1.0 ? num.toFixed(2) : num.toPrecision(2)
    } else {
      return '...'
    }
  }


  const handleAccordionClick = (id) => {
    (currentSectionId === id) ? setCurrentSectionId(null) : setCurrentSectionId(id)
  }

  const createLabel = (context) => {
    let label = context.dataset.data[context.dataIndex]
    if (typeof label === "number") {
      label = Math.abs(label) >= 1.0 ? label.toFixed(2) : label.toPrecision(2)
    }else{
      label = 0
    }
    label += ' MTCO2e'                         
    return label
  }


  const generateData = () => {
    return {
      labels: orgSummary.map((d) => d.name),
      datasets: [{
        data: orgSummary.map((d) => d.kgs),
        borderWidth: 0,
        backgroundColor: orgSummary.map((d) => orgSummaryColors[d.name]),
        hoverOffset: 4
      }]
    }
  }

  const generateScopeSummaryData = () => {
    return {
      labels: scopeSummary.map((d) => d.name),
      datasets: [{
        data: scopeSummary.map((d) => d.kgs),
        borderWidth: 0,
        backgroundColor: scopeSummary.map(d => scopeSummaryColors[d.name]),
        hoverOffset: 4
      }]
    }
  }

  const scopeSummaryLabel = (context) => {
    let label = context.dataset.data[context.dataIndex]
    if (typeof label === "number") {
      label = Math.abs(label) >= 1.0 ? label.toFixed(2) : label.toPrecision(2)
    }else{
      label = 0
    }
    label += ' MTCO2e'                         
    return label
  }

  
const combineAndSortDataWithColors = (sections) => {
  // Combine all items into a single array
  const combinedData = sections.flatMap(({ data, getLabel, getData }) => 
    data.flatMap((item) => {
      if (getData(item) !== 0) {
        return ({
          label: getLabel(item),
          value: getData(item),
        })
      }
    })
  );

  // Sort combined data alphabetically by label
  combinedData.sort((a, b) => a.label.localeCompare(b.label));

  // Take out undefined
  const compressedData = combinedData.filter(Boolean)

  // Generate colors for the sorted combined data
  const totalItems = compressedData.length;
  const colors = getGradientColors(totalItems);


  // Integrate colors into the combined data
  return compressedData.map((item, idx) => ({
    ...item,
    color: colors[idx],
  }));
};

// Usage
const combinedDataWithColors = useMemo(() => combineAndSortDataWithColors([
  {
    data: procurement,
    getLabel: d => d.label,
    getData: d => d.total_kgco2e,
  },
  {
    data: kitchenSection?.emissions_calculations || [],
    getLabel: d => d.label,
    getData: d => d.total_kgco2e,
  },
  {
    data: scope3Summary,
    getLabel: d => d.question_category.name,
    getData: d => d.total_kgco2e,
  }
]), [procurement, kitchenSection, scope3Summary]);

const generateCombinedTableRows = ( numberOfTables, tableNumber ) =>
  combinedDataWithColors.map((item, idx) => {
    return (
      idx >= (tableNumber * Math.ceil(combinedDataWithColors.length / numberOfTables)) &&
      idx < (tableNumber + 1) * Math.ceil(combinedDataWithColors.length / numberOfTables) && (
        <tr key={item.label}>
          <td>
            <span className='labelContainer'>
              <span className="keySwatch" style={{ backgroundColor: item.color }}></span>
              <span className='label'>{item.label.split(" ").map(x => capitalizeFirstLetter(x)).join(" ")}</span>
            </span>
          </td>
          <td>{smallOrLargeNumber(item.value * 0.001)}</td>
          <td>{smallOrLargeNumber((item.value / scopeMap['SCOPE_3']) * 100.0)}%</td>
        </tr>
      )
    );
  });

  const CombinedTableDisplay = ({ deviceType }) => {
  const numberOfTables = deviceType === 'tablet' ? 2 : 3;

  return (
    <>
      {Array(numberOfTables).fill('0').map((_, idx) => {
        return (
          <table className="legend" key={idx}>
            <thead>
              <tr>
                <td>Type</td>
                <td colSpan="3">Impact (MTCO2e)</td>
              </tr>
            </thead>
            <tbody>
              {generateCombinedTableRows(numberOfTables, idx)}
            </tbody>
          </table>
        );
      })}
    </>
  );
};

const selectedReportId = reportId ? reportId : reportSummary?.id

console.log('selected id is', selectedReportId)

const Header = (
  <header>
    <h2>Carbon Footprint</h2>
    {(questionnaires.length > 1) && <LabeledSelect 
      name="reportId"
      options={questionnaires.map( qnn => ({label: qnn.year, value: qnn.report_id}))}
      values={{reportId: selectedReportId}}
      onChange={(newVal) => setReportId(newVal.target.value)}
    />}
  </header>
)

  if(loading) {
    return (
      <div className="page" id="insights">
        {Header}
        <div className="muiLoader">
          <CircularProgress/>
        </div>
      </div>
    )
  }


  return(
    <div className="page" id="insights">
      {Header}
      <div>
        <div className="statsBlock homeGreen">
         {statBlocks.map ( block => <HomeStatBlock {...block} />)}
        </div>
      </div>
      <div className="chartsBlock">
        <div className="chart">
          <header>
            <h4>Organization Summary</h4>
          </header>
          <div className='bodyContainer'>
            <div className="donutHolder">
              <div className="donutMeat">
                <Donut
                  data={generateData()}
                  label={createLabel}
                />
                <div className="donutHole">
                  <h5>Total</h5>
                  <div className="body4">
                    {Math.ceil(total * 0.001)} MTCO2e
                  </div>
                </div>
              </div>
            </div>

            <table className="legend">
              <thead>
                <tr>
                  <td>Type</td><td colSpan="3">Impact (MTCO2e)</td>
                </tr>
              </thead>
              <tbody>
                { impactTableRows }
              </tbody>
            </table>  
          </div>  
        </div>
        <div className="chart">
          <header>
            <h4>Summary by GHG Type</h4>
          </header>
          <div className='bodyContainer'>
            <div className="barHolder">
              <Bar
                colors={substanceSummaryColors}
                labels={substanceSummaryLabels}
                data={substanceSummaryData}
              />
            </div>
            <table className="legend">
              <thead>
                <tr>
                  <td>Type</td><td colSpan="2">Impact (MTCO2e)</td>
                </tr>
              </thead>
              <tbody>
                { substanceTableRows }
              </tbody>
            </table>
          </div>
        </div>
        <div className="chart">
          <header>
            <h4>Emissions by Scope</h4>
          </header>
          <div className='bodyContainer'>
          <div className="donutHolder">
            <div className="donutMeat">
              <Donut
                data={generateScopeSummaryData()}
                label={scopeSummaryLabel}
              />
              <div className="donutHole">
                <h5>Total</h5>
                <div className="body4">
                  {Math.ceil(total * 0.001)} MTCO2e
                </div>
              </div>
            </div>
          </div>
          <table className="legend">
            <thead>
              <tr>
                <td>Type</td><td colSpan="2">Impact (MTCO2e)</td>
              </tr>
            </thead>
            <tbody>
              { scopeTableRows }
            </tbody>
          </table>
          </div>
        </div>
      </div>
      <div className="chartsBlock scope3">
        <div className="chart">
          <header className="tabbed">
            <h4>Scope 3 Breakdown</h4>
            <div className="toggleHeader">
              <div className={`tab general ${currentBar === 'general' ? 'current' : ''}`} onClick={() => {setCurrentBarChart('general')} }>
                <h5>General</h5>
              </div>
            </div>
          </header>
          <div className={`chartHolder ${currentBar === 'general' ? '' : 'hidden'}`}>
            <div className='canvasContainer'>
              <Bar
              colors={combinedDataWithColors.map(datum => datum.color)}
              labels={combinedDataWithColors.map(datum => datum.label)}
              data={combinedDataWithColors.map(datum => datum.value)}
              kgToTonne={true}
            />
            </div>
            <div className='tableContainer'>   
              <CombinedTableDisplay deviceType={deviceType}   />  
            </div>
          </div>
        </div>
      </div>

      <div className="mobilePrompt">     
          <div className='image'><img src={DashboardIcon} alt="three vertical bars"/></div>
          <div className='body3'>To view more of your footprint details, open ClimateHound in your desktop browser.</div>
      </div>

      <div className="accordionBlock">
        <h4>Emissions by Category</h4>

        { bySection && bySection.map((section) => (
            <EmissionSection 
              year={year}
              section={section} 
              handleAccordionClick={handleAccordionClick} 
              currentSectionId={currentSectionId}
              previousSection={bySectionPrevious.find(sctn => sctn.section_id === section.section_id)}
            />
          ))
        }
      </div>
    </div>
  )
}
