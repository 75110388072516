import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import { AgGridReact } from 'ag-grid-react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import LockResetIcon from '@mui/icons-material/LockReset';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import axios from 'axios'

export default function Sandbox() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [ users, setUsers ] = useState([])
  const [ templateUsers, setTemplateUsers ] = useState([])
  const [ email, setEmail ] = useState('loading')
  const [ password, setPassword ] = useState('')

  const [ isLoading, setIsLoading ] = useOutletContext()

  const [ selectedTemplateUserId, setSelectedTemplateUserId ] = useState()

  const resetPassword = (user) => {
    console.log(user)
    if (window.confirm(`Send password reset to ${user.email}?`)) {
      console.log('reset')
      axios.post(`/api/users/${user.id}/send_reset_password_email`).then((res) => {
        console.log(res)
      })
    }
  }

  const setTestEmail = (users) => {
    const latestEmail = users[0].email
    setEmail(incrementEmailSequence(latestEmail))
  }

  function incrementEmailSequence(email) {
    const emailRegex = /^([^@]+)@(.+)$/ // Regex to split the local part and domain

    const match = email.match(emailRegex)
    if (!match) {
      return email
    }

    const [_, localPart, domain] = match

    // Match a number at the end of the local part
    const numberRegex = /^(.*?)(\d+)$/
    const numberMatch = localPart.match(numberRegex)

    if (numberMatch) {
      const [, base, number] = numberMatch
      const incrementedNumber = parseInt(number, 10) + 1
      return `${base}${incrementedNumber}@${domain}`
    }

    // No number in the local part, return the original email
    return email
  }

  const deleteAccount = (user) => {
    if (window.confirm('Delete user ' + user.email + '?')) {
      axios.delete('/api/users/' + user.id).then((res) => {
        window.location = window.location
      })
    }
  }


  useEffect(() => {
    axios.get('/api/users?is_template=true').then((res) => {
      setTemplateUsers(res.data)
    })
    axios.get('/api/users?is_sandbox=true&is_template=false').then((res) => {
      setUsers(res.data)
      setTestEmail(res.data)
    })
  }, [])

  const userColDefs = [
    {field: "email", flex: 2, cellRenderer: (props) => (
      <span>{props.data.settings?.netZeroSettings && "🍂🍃"}{props.data.is_launch_partner && "🚀🤝"} {props.value}</span>
    )},
    {field: "current_sus_page_id", headerName: "Sus Page", flex: 1, cellRenderer: (props) => (
      <div className="icon-container browser">
        <OpenInBrowserIcon 
          onClick={() => window.open(`${process.env.REACT_APP_ROOT_URL}/profile/${btoa(props.value)}`, '_blank')}
        />
      </div>
    )},
    {field: "first_name", headerName: "First Name", flex: 1},
    {field: "last_name", headerName: "Last Name", flex: 1},
    {field: "company", flex: 1.3},
    {field: "gone_neutral", headerName: "Carbon Neutral", flex: 1, cellRenderer: (props) => (
      <span>{props.value ? "YES" : 'no'}</span>
    )},
    {field: "calc_due_in_days", headerName: "Due in Days", flex: 1},
    {field: "id", headerName: "Edit", flex: 1, cellRenderer: (props) => (
      <div className="action-icons">
        <div className="icon-container reset-pw"><LockResetIcon onClick={() => { resetPassword(props.data) }}/></div>
        <div className="icon-container edit"><Link to={`/admin/users/${props.value}`}><EditIcon/></Link></div>
        <div className="icon-container edit"><DeleteIcon onClick={() => { deleteAccount(props.data) }} /></div>
      </div>
    )},
  ]

  const autoSizeStrategy = {
    type: 'fitGridWidth'
  };

  const makeUser = () => {
    const userPayload = {
      user: {
        email: email,
        password: password
      },
      template_user_id: selectedTemplateUserId
    }
    setIsLoading(true)
    axios.post('/api/users', userPayload).then((res) => {
      console.log('done')
      console.log(res)
      window.location = window.location
    }).catch((e) => {
      window.alert('Something went wrong')
      setIsLoading(false)
    })
  }

  const setSelectedTemplate = (e) => {
    console.log(e.target.value)
    setSelectedTemplateUserId(e.target.value)
  }

  return(
      <div className="adminPage admin-table-wrapper">

        <header className="pageHeader">
          <h1>Sandbox Users</h1>
        </header>

        <div className="sandbox">
          <select
            value={selectedTemplateUserId}
            onChange={setSelectedTemplate}
          >
            <option>- select template -</option>
            { templateUsers.map((tu) => (<option key={tu.id} value={tu.id}>{tu.email}</option>)) }
          </select>
          <input type="text" value={email} placeholder='email' onChange={(e) => (setEmail(e.target.value))} />
          <input type="text" value={password} placeholder='password' onChange={(e) => (setPassword(e.target.value))} />
          <input
            type="button"
            onClick={makeUser}
            value={`Make User`}
          />
        </div>

        {!!users.length && (
            <div className="ag-theme-quartz full-container-table">
              <AgGridReact 
                domLayout='autoHeight'
                suppressDragLeaveHidesColumns 
                rowData={users} 
                columnDefs={userColDefs} 
                autoSizeStrategy={autoSizeStrategy}
                enableCellTextSelection
                ensureDomOrder
              />
            </div>
          )}

      </div>
  )
}
