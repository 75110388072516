import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import LabeledField from '../Forms/LabeledField'
import Selectable from '../Forms/Selectable'
import MiniProgress from './MiniProgress'
import NavButtons from './NavButtons'
import { useNavigate } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import FileUploadComponent from '../Forms/DropUpload'

import { ReactComponent as Xsvg } from '../../assets/X.svg'

import axios from 'axios'

export default function SupplierSpends(props) {

  const emptyRow = {id: 'i0', name: '', sq_ft: ''}
  const defaultAnswer = cloneDeep(props.question?.answer?.warehouses)
  const [ rows, setRows ] = useState(defaultAnswer || [])
  const [ errors, setErrors ] = useState({})

  const updateRow = (id, e) => {
    setErrors({})
    const newRows = cloneDeep(rows)
    const idx = newRows.findIndex((s) => (s.id === id))
    const newRow = {...newRows[idx]}
    console.log('we have', newRow)
    newRow[e.target.name] = e.target.value

    newRows.splice(idx, 1, newRow)

    setRows(newRows)
  }

  const addRow = () => {
    setErrors({})
    const newTable = cloneDeep(rows)
    const newRow = {...emptyRow}

    newTable.splice(newTable.length, 0, {...newRow, id: "i" + newTable.length})

    setRows(newTable)
  }

  const removeRow = (id) => {
    let newTable = cloneDeep(rows)

    const idx = newTable.findIndex((s) => (s.id === id))
    console.log(id)
    console.log(newTable)

   
    if (!window.confirm(`Are you sure you want to remove it?`)) {
      return
    }

    newTable.splice(idx, 1)

    for (let i = 0; i < newTable.length; i++) {
      if ((`${newTable[i].id}`).indexOf('i') > -1) {
        newTable[i].id = "i" + i
      }
    }

    console.log(newTable)

    setRows(newTable) 
  }

  const submit = () => {
    console.log(rows)
    setErrors({})
    const newErrors = {}
    rows.forEach((row, idx) => {
      let id = row.id ? row.id : 'i'+idx
      const rowErrors = {}
      const valNoCommas = row.sq_ft.replace(/\,/g, '')

      for (let i = 0; i < idx; i++) {
        if (rows[i].name === row.name) {
          rowErrors['name'] = "You’ve already used this description."
        }
      }
      if (row.name.length === 0) {
        rowErrors['name'] = "Required"
      }
      if (!(/^\d+$/.test(valNoCommas))) {
        rowErrors['sq_ft'] = "Please enter whole number."
      }
      if (row.sq_ft.length === 0) {
        rowErrors['sq_ft'] = "Required"
      }
      if (Object.keys(rowErrors).length > 0) {
        newErrors[id] = rowErrors
      }
    })

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      console.log('Errors:', newErrors)
    } else {
      console.log('No errors, ready to submit')
      console.log(props.question)
      console.log(rows)
      return axios.patch('/api/questions/' + props.question.id, {answer: {warehouses: rows}})
    }

  }

  function nextEnabled() {
    if (rows.length === 0) {
      return false
    }
    for (let i = 0; i < rows.length; i++) {
      if (!rows[i].name || rows[i].name.length === 0) {
        return false
      }
      if (!rows[i].sq_ft || rows[i].sq_ft.length === 0) {
        console.log('no size')
        return false
      }
    }
    return true
  }

  return (
    <div>
      <div className="questionInputs bulkSuppliers">
        <div>
          {
            rows.map((row) => {
              return (
                <div className="supplier" key={row.id}>
                  <div className="fields">
                    <div className="name">
                      <LabeledField
                        name="name"
                        label="Description"
                        formErrors={errors[row.id]}
                        values={row}
                        onChange={(e) => {updateRow(row.id, e)}}
                        placeholder="Name"
                      />
                    </div>
                    <div className="spend">
                      <LabeledField
                        name="sq_ft"
                        label="Size"
                        formErrors={errors[row.id]}
                        values={row}
                        onChange={(e) => {updateRow(row.id, e)}}
                        placeholder="sq ft"
                      />
                    </div>
                    <div
                      className="removeButton"
                      onClick={() => {removeRow(row.id)}}
                    >
                      <Xsvg />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="addButtonHolder">
          <input type="button" className="gray addButton" value="Add another" onClick={addRow} />
        </div>
      </div>
      <FileUploadComponent folder={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
      />
    </div>
  )
}
