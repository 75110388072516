import React, { useState } from 'react'

import LabeledField from '../Forms/LabeledField'
import NavButtons from './NavButtons'
import FileUploadComponent from '../Forms/DropUpload'
import axios from 'axios'

import validateAnswer from '../../utils/validateAnswer'

export default function SingleAnswerQuestion(props) {

  const defaultAnswer = {...props.question.answer}
  const {answer_format, id, units_name } = props.question
  const [ answer, setAnswer ] = useState(defaultAnswer)
  const [ errors, setErrors ] = useState({})

  const updateAnswer = (e) => {
    setErrors({})
    const newAnswer = { ...answer }
    newAnswer[e.target.name] = e.target.value
    setAnswer(newAnswer)
  }

  const submit = () => {
    let newErrors = validateAnswer(props.question, answer)

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      return axios.patch('/api/questions/' + id, {
        answer: answer
      })
    }
  }

  const nextEnabled = () => {
    return answer?.text?.length > 0
  }

  return (
    <div>
      <div className="questionInputs">
        <div className={"oneField" + (units_name === '$' ? " dollarAmt" : " nonDollarAmt")}>
          <LabeledField
            name="text"
            formErrors={errors}
            values={answer}
            placeholder={"Total"}
            onChange={updateAnswer}
          />
          <aside className={units_name === '$' ? "dollarAmt" : "nonDollarAmt"}>{units_name}</aside>
        </div>
        <FileUploadComponent folder={props.question.id} />
        <NavButtons
          submit={submit}
          question={props.question}
          goPrev={props.goPrev}
          goNext={props.goNext}
          nextEnabled={nextEnabled}
        />
      </div>
    </div>
  )
}
