import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CheckBox from '../Forms/CheckBox'
import { cloneDeep } from 'lodash'

export default function NavButtons(props) {

  const [ question, setQuestion ] = useState(cloneDeep(props.question))
  const [ nextEnabled, setNextEnabled ] = useState(false)

  const questionSaysItsValidToSubmit = (props) => {
    if (typeof props.nextEnabled === 'function') {
      return props.nextEnabled()
    } else {
      return props.nextEnabled
    }
  }

  useEffect(() => {
    let nextEnabledDecision = false

    if (question.status === 5) {
      nextEnabledDecision = false
    }

    else if (question.prefill_year && !question.prefill_confirmed) {
      nextEnabledDecision = false
    }

    else if (question.not_applicable) {
      nextEnabledDecision = true
    }

    else if (questionSaysItsValidToSubmit(props)) {
      nextEnabledDecision = true
    }

    else if (question.not_applicable) {
      nextEnabledDecision = true
    }

    else {
      nextEnabledDecision = props.nextEnabled
    }

    setNextEnabled(nextEnabledDecision)
  }, [question, props.question, props.nextEnabled])



  const updateQuestion = (e) => {
    const newQuestion = cloneDeep(question)
    // good practice to check to see if you're sending a boolean
    // value or if you are trying to set a value to another type
    if (Object.keys(e.target).includes('checked')) {
      newQuestion[e.target.name] = e.target.checked
    } else {
      newQuestion[e.target.name] = e.target.value
    }
    setQuestion(newQuestion)
  }

  const skipAndMark = () => {
    axios.post(`/api/questions/${question.id}/mark_skipped`).then((res) => {
      props.goNext('skip')
    })
  }

  const wrappedSubmit = () => {
    axios.post(`/api/questions/${question.id}/mark_${question.not_applicable ? 'n' : ''}a`).then((res) => {
      if (questionSaysItsValidToSubmit(props)) {
        if (question.prefill_year) {
          axios.post(`/api/questions/${question.id}/mark_prefill_confirmed`).then((res) => {
            props.submit()?.then(() => {
              props.goNext()
            })
          })
        } else {
          props.submit().then(() => {
            props.goNext()
          })
        }
      } else {
        skipAndMark()
      }
    }).catch((e) => {
      console.log('caught submit error')
      console.error(e)
    })
  }

  return (
    <div className={`questionActions ${props.noTopMargin ? 'noTopMargin' : ''}`}>
      <div className='checkBoxContainer'>
        <CheckBox
          name="not_applicable"
          values={question}
          onChange={updateQuestion}
          label='Not applicable'
          disabled={question.status === 5}
        />
      </div>
      {
        question.prefill_year && (
          <div className='confirmPrefillPrompt'>
            <span className="prefillDetails">
              We’ve populated this question with data from your most recent carbon footprint. Please review details, adjust as needed, and check this box to confirm.
            </span>
            <div className='checkBoxContainer'>
              <CheckBox
                name="prefill_confirmed"
                values={question}
                onChange={updateQuestion}
              />
            </div>
          </div>
        )
      }
      <div className="buttons">
        <input type="button" className="skeleton" value="Skip" onClick={skipAndMark} />
        <input type="button" value="Back" onClick={props.goPrev} />
        <input type="button" className="positive" value="Next" onClick={wrappedSubmit} disabled={!nextEnabled && !question.status !== 5} />
      </div>
    </div>
  )
}
